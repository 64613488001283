<template>
  <div
    :id="'question_wrapper_' + question.id"
    class="question_main_wrapper"
  >
    <div class="progress_bar_outer">
      <ProgressBar />
      <HelpOverlay type="progress" />
    </div>

    <div class="question_main">
      <div class="question-island">
        {{ $t('islands.'+island.name.toLowerCase()+'.name') }}
      </div>

      <transition
        name="custom-fade"
        enter-active-class="animated fadeIn"
        leave-active-class="animated fadeOut"
      >
        <div
          id="question_text_container"
          class="question_text_container text_container"
        >
          <div
            id="question_text"
            class="question_text"
            v-html="question.questionText"
          />
        </div>
      </transition>

      <NarrationButtons @continue="continueQuestion" />

      <HelpOverlay type="question" />
    </div>

    <transition
      name="custom-fade"
      enter-active-class="animated fadeInLeft"
    >
      <div
        v-show="narrationPlayedOnce && !questionAnswered"
        id="narration_end_arrow"
      />
    </transition>
  </div>
</template>

<script>
import ProgressBar from '@/components/ProgressBar'
import NarrationButtons from '@/components/NarrationButtons'
import HelpOverlay from '@/components/HelpOverlay'

export default {
  name: 'Question',
  components: {
    ProgressBar,
    NarrationButtons,
    HelpOverlay
  },
  computed: {
    island() {
      return this.$store.getters.islands[this.softSkillIdx]
    },
    question() {
      return this.$store.getters.question
    },
    narrationPlayedOnce() {
      return this.$store.getters.narrationPlayedOnce
    },
    questionAnswered() {
      return this.$store.getters.questionAnswered
    }
  },
  created() {
    this.initQuestion()
  },
  mounted() {
    this.$store.commit('setQuestionStartTime', Date.now())
    const container = jQuery('#question_wrapper_' + this.question.id)
    container.find('.question_text_container').mCustomScrollbar({ theme: 'inset-dark' })
    container.find('.help_txt').mCustomScrollbar({ theme: 'minimal' })

    this.unloadBgSound()

    this.startNarration(true)
  },
  methods: {
    unloadBgSound() {
      if (this.bgSound) {
        this.bgSound.unload()
        this.$store.commit('setBgSound', null)
      }
    },

    initQuestion() {
      this.$store.commit('setNarrationFinished', false)
      this.$store.commit('setNarrationPlayedOnce', false)
      this.$store.commit('setQuestionAnswered', false)
      this.$store.commit('setBestOptionId', 0)
      this.$store.commit('setWorstOptionId', 0)
    },

    continueQuestion() {
      this.resetNarrationSound()
      this.initQuestion()
      this.$parent.$parent.routeGame()
    }
  }
}
</script>
