import { render, staticRenderFns } from "./Answers.vue?vue&type=template&id=f750b016&scoped=true&"
import script from "./Answers.vue?vue&type=script&lang=js&"
export * from "./Answers.vue?vue&type=script&lang=js&"
import style0 from "./Answers.vue?vue&type=style&index=0&id=f750b016&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f750b016",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\soiko\\Documents\\Projects\\game\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f750b016')) {
      api.createRecord('f750b016', component.options)
    } else {
      api.reload('f750b016', component.options)
    }
    module.hot.accept("./Answers.vue?vue&type=template&id=f750b016&scoped=true&", function () {
      api.rerender('f750b016', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Answers.vue"
export default component.exports