var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "game_question" } },
    [
      _c(
        "div",
        {
          key: _vm.question.id,
          staticClass: "question_outer bg_screen",
          class: _vm.question.cssClass,
          style: { "background-image": "url(" + _vm.question.bgImg + ")" },
          attrs: { id: "question_" + _vm.question.id },
        },
        [
          _c(
            "div",
            {
              staticClass: "question_avatar flipped",
              attrs: { id: "question_avatar" + _vm.avatar.id },
            },
            [
              _c("img", {
                attrs: { src: _vm.avatar.img, alt: _vm.avatar.name },
              }),
            ]
          ),
          _c("a", {
            staticClass: "img-btn helper-btn",
            attrs: { id: "help-button" },
            on: { click: _vm.toggleHelp },
          }),
          _c("a", {
            staticClass: "img-btn helper-btn",
            attrs: { id: "journal-button" },
            on: { click: _vm.openJournal },
          }),
          _c("a", {
            staticClass: "img-btn img-hover-btn helper-btn",
            class: { isolated: _vm.bgIsolated },
            attrs: { id: "viewbg-button" },
            on: { click: _vm.isolateBg },
          }),
          _vm.typeOfUser != null && _vm.typeOfUser != "neutral"
            ? _c("div", { staticClass: "leaderboard_position" }, [
                _c("h3", [_vm._v("Leaderboard")]),
                _c("h2", [_vm._v(_vm._s(_vm.leaderboardRank) + " out of 100")]),
              ])
            : _vm._e(),
          _c(
            "h2",
            { staticClass: "player_name", class: "play_name_" + _vm.avatar.id },
            [_vm._v("\n      " + _vm._s(_vm.avatar.name) + "\n    ")]
          ),
          _c(
            "div",
            { staticClass: "question_wrapper", class: "font" + _vm.fontSize },
            [
              _c(
                "div",
                { staticClass: "cell cell-8-col question_col" },
                [_c("Question")],
                1
              ),
              _c(
                "div",
                { staticClass: "cell cell-4-col question_col" },
                [_c("Answers", { ref: "answers" })],
                1
              ),
            ]
          ),
        ]
      ),
      _vm.showJournal ? _c("Journal") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }