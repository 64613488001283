<template>
  <div
    id="end_screen"
    class="bg_screen"
  >
    <div class="congratulations-box">
      <p :style="styling">
        <template v-if="isEarly">
          <span v-html="$t('congratulations_early_1', { name })" />
          <span
            v-if="!isPiraeus"
            v-html="$t('congratulations_early_2')"
          />
        </template>
        <span
          v-else-if="hasNoMoreAvailableThisMonth"
          v-html="$t('congratulations_no_more_this_month')"
        />
        <span
          v-else
          v-html="$t('congratulations', { name })"
        />

        <br>

        <a
          v-if="!isPiraeus"
          :href="dashboardGameScreen"
        >
          {{ $t("show_results") }}
        </a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EndScreen',
  computed: {
    dashboardGameScreen() {
      return process.env.VUE_APP_DASHBOARD_GAME_SCREEN
    },

    isEarly() {
      if (Object.keys(this.$route.query).length) {
        return this.$route.query.finishEarly
      }
      return false
    },

    hasNoMoreAvailableThisMonth() {
      if (Object.keys(this.$route.query).length) {
        return this.$route.query.noMoreAvailableThisMonth
      }
      return false
    },

    name() {
      return this.$store.getters.name
    },

    isPiraeus() {
      return this.$store.getters.isPiraeus
    },

    styling() {
      if (this.isEarly || this.hasNoMoreAvailableThisMonth) {
        return this.$i18n.locale === 'el' ? 'font-size: 0.7em' : 'font-size: 0.75em'
      }

      return ''
    }
  },

  created() {
    this.$parent.$refs.header.allowMuteNarration = false
    this.$parent.$refs.header.allowFontEdit = false

    this.resetNarrationSound()
  }
}
</script>
