var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: {
        name: "custom-fade",
        "enter-active-class": "animated fadeIn",
        "leave-active-class": "animated fadeOut",
        appear: "",
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showHelp,
              expression: "showHelp",
            },
          ],
          staticClass: "help_overlay",
          attrs: { id: _vm.type + "_help" },
        },
        [
          _c("a", {
            staticClass: "img-btn info-button",
            on: {
              click: function ($event) {
                return _vm.toggleHelpOverlay()
              },
            },
          }),
          _c(
            "transition",
            {
              attrs: {
                name: "custom-slide",
                "enter-active-class": "animated slideInUp",
                "leave-active-class": "animated fadeOut",
                appear: "",
              },
            },
            [
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showHelpPopup,
                    expression: "showHelpPopup",
                  },
                ],
                staticClass: "help_txt",
                domProps: { innerHTML: _vm._s(_vm.$t(_vm.type + "_help")) },
              }),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }