<template>
  <div class="progress_bar">
    <div class="progress-title">
      <span v-html="`${$t('chapter')} ${currentSoftSkillIdx} ${$t('of')} ${softSkillCnt}`" />
    </div>
    <div class="progress_pointer_wrap">
      <span
        v-for="qIndex in questionsCnt"
        :key="qIndex"
        class="progress_pointer"
        :data-cnt="qIndex"
        :class="{passed: (qIndex < currentQuestionIdx), current: (qIndex == currentQuestionIdx)}"
        :style="{width: 100 / questionsCnt + '%'}"
      />
    </div>
    <div class="progress_islands">
      <IslandPreview
        v-for="(island, iIndex) in islands"
        :id="'progress_island_' + iIndex"
        :key="island.name+'_'+iIndex"
        class="progress-island"
        :class="{
          passed: (iIndex < softSkillIdx),
          current: (iIndex == softSkillIdx),
          next: (iIndex == softSkillIdx + 1)
        }"
        :style="{
          left: ((iIndex < currentSoftSkillIdx) ? -6.3 - (getMaxIndex(softSkillIdx) - iIndex)*3 + '%' : 'auto'),
          right: ((iIndex >= currentSoftSkillIdx) ? -6.3 - (iIndex - currentSoftSkillIdx)*3 + '%' : 'auto'),
          width: getIslandDimensions(iIndex),
          height: getIslandDimensions(iIndex),
          'z-index': (iIndex < currentSoftSkillIdx) ? 99 + iIndex : 99 - iIndex
        }"
        :name="island.name"
        :status="(currentSoftSkillIdx > iIndex) ? 'completed' : 'pending'"
        :title="island.name"
      />
    </div>
  </div>
</template>

<script>
import IslandPreview from '@/components/IslandPreview'

export default {
  name: 'ProgressBar',
  components: {
    IslandPreview
  },
  computed: {
    islands() {
      return this.$store.getters.islands
    },
    currentSoftSkillIdx() {
      return 1 + this.softSkillIdx
    },
    softSkillCnt() {
      const limit = this.$store.getters.gameplay.softSkills.length
      if (this.currentSoftSkillIdx <= limit) { return limit }
      return this.currentSoftSkillIdx
    },
    questionsCnt() {
      return this.$store.getters.gameplay.softSkills[this.$store.getters.softSkillIdx].questions.length
    },
    currentQuestionIdx() {
      return 1 + this.$store.getters.questionIdx
    }
  },
  methods: {
    getMaxIndex(iIndex) {
      return iIndex < 8 ? iIndex : 8
    },

    getIslandDimensions(iIndex) {
      const index = this.softSkillIdx > 8 ? 8 : this.softSkillIdx
      return 84 - ((iIndex >= this.currentSoftSkillIdx) ? iIndex - this.currentSoftSkillIdx : index - iIndex) * 8 + 'px'
    }
  }
}
</script>

<style scoped>
  #progress_island_8:not(.next),
  #progress_island_9:not(.next),
  #progress_island_10:not(.next) {
    display: none;
  }
</style>
