<template>
  <div id="game_question">
    <div
      :id="'question_' + question.id"
      :key="question.id"
      class="question_outer bg_screen"
      :class="question.cssClass"
      :style="{'background-image': 'url(' + question.bgImg + ')'}"
    >
      <div
        :id="'question_avatar' + avatar.id"
        class="question_avatar flipped"
      >
        <img
          :src="avatar.img"
          :alt="avatar.name"
        >
      </div>

      <a
        id="help-button"
        class="img-btn helper-btn"
        @click="toggleHelp"
      />

      <a
        id="journal-button"
        class="img-btn helper-btn"
        @click="openJournal"
      />

      <a
        id="viewbg-button"
        class="img-btn img-hover-btn helper-btn"
        :class="{ isolated: bgIsolated }"
        @click="isolateBg"
      />

      <div
        class="leaderboard_position"
        v-if="typeOfUser != null && typeOfUser != 'neutral'"
      >
        <h3>Leaderboard</h3>
        <h2>{{leaderboardRank}} out of 100</h2>
      </div>

      <h2
        class="player_name"
        :class="`play_name_${avatar.id}`"
      >
        {{ avatar.name }}
      </h2>

      <div
        class="question_wrapper"
        :class="'font' + fontSize"
      >
        <div class="cell cell-8-col question_col">
          <Question />
        </div>
        <div class="cell cell-4-col question_col">
          <Answers ref="answers" />
        </div>
      </div>
    </div>
    <Journal v-if="showJournal" />
  </div>
</template>

<script>
import Question from '@/components/Question.vue'
import Answers from '@/components/Answers.vue'

export default {
  name: 'GameQuestionContainer',
  components: {
    Question,
    Answers,
    Journal: () => import('@/components/Journal.vue')
  },
  data() {
    return {
      showHelp: false
    }
  },
  computed: {
    showJournal() {
      return this.$store.getters['journal/visible']
    },

    avatar() {
      return this.$store.getters.avatar
    },

    header() {
      return this.$parent.$refs.header
    },

    fontSize() {
      return this.$store.getters.fontSize
    },

    questionIdx() {
      return this.$store.getters.questionIdx
    },

    leaderboardUsers() {
      return this.$store.getters.leaderboardUsers
    },

    userEmail() {
      return this.$store.getters.email
    },

    typeOfUser() {
      if (this.leaderboardUsers.good.includes(this.userEmail)) {
        return 'good'
      }

      if (this.leaderboardUsers.bad.includes(this.userEmail)) {
        return 'bad'
      }

      if (this.leaderboardUsers.neutral.includes(this.userEmail)) {
        return 'neutral'
      }

      return null
    },

    leaderboardRank() {
      if (this.softSkillIdx == 0) {
        if (this.questionIdx == 0) {
          return this.typeOfUser == 'good' ? '-' : '-'
        }

        if (this.questionIdx == 1) {
          return this.typeOfUser == 'good' ? '44th' : '53th'
        }

        if (this.questionIdx == 2) {
          return this.typeOfUser == 'good' ? '29th' : '68th'
        }
      }

      if (this.softSkillIdx == 1) {
        if (this.questionIdx == 0) {
          return this.typeOfUser == 'good' ? '18th' : '79th'
        }

        if (this.questionIdx == 1) {
          return this.typeOfUser == 'good' ? '10th' : '87th'
        }

        if (this.questionIdx == 2) {
          return this.typeOfUser == 'good' ? '8th' : '89th'
        }
      }

      if (this.softSkillIdx == 2) {
        if (this.questionIdx == 0) {
          return this.typeOfUser == 'good' ? '7th' : '90th'
        }

        if (this.questionIdx == 1) {
          return this.typeOfUser == 'good' ? '8th' : '89th'
        }

        if (this.questionIdx == 2) {
          return this.typeOfUser == 'good' ? '7th' : '90th'
        }
      }

      if (this.softSkillIdx == 3) {
        if (this.questionIdx == 0) {
          return this.typeOfUser == 'good' ? '6th' : '91th'
        }

        if (this.questionIdx == 1) {
          return this.typeOfUser == 'good' ? '7th' : '90th'
        }

        if (this.questionIdx == 2) {
          return this.typeOfUser == 'good' ? '6th' : '91th'
        }
      }

      return 0
    },

    question() {
      return this.$store.getters.question
    },

    bgIsolated() {
      return this.$parent.bgIsolated
    }
  },
  created() {
    this.$store.dispatch('getLeaderboardUsers', 'good')
    this.$store.dispatch('getLeaderboardUsers', 'bad')
    this.$store.dispatch('getLeaderboardUsers', 'neutral')
    this.header.allowMuteNarration = true
    this.header.allowFontEdit = true
    this.$parent.questionPlaying = true
    this.$store.commit('setNarrationFinished', false)
    window.addEventListener('keydown', this.escButton)
  },
  methods: {
    isolateBg() {
      this.$parent.bgIsolated = !this.$parent.bgIsolated
    },
    toggleHelp() {
      this.sideBtnSound.play()
      this.showHelp = !this.showHelp
    },
    openJournal() {
      this.sideBtnSound.play()
      this.$store.dispatch('journal/open')
    },
    escButton(e) {
      if (e.keyCode === 27) {
        this.$parent.bgIsolated = false
      }
    }
  }
}
</script>
