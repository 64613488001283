var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "progress_bar" }, [
    _c("div", { staticClass: "progress-title" }, [
      _c("span", {
        domProps: {
          innerHTML: _vm._s(
            _vm.$t("chapter") +
              " " +
              _vm.currentSoftSkillIdx +
              " " +
              _vm.$t("of") +
              " " +
              _vm.softSkillCnt
          ),
        },
      }),
    ]),
    _c(
      "div",
      { staticClass: "progress_pointer_wrap" },
      _vm._l(_vm.questionsCnt, function (qIndex) {
        return _c("span", {
          key: qIndex,
          staticClass: "progress_pointer",
          class: {
            passed: qIndex < _vm.currentQuestionIdx,
            current: qIndex == _vm.currentQuestionIdx,
          },
          style: { width: 100 / _vm.questionsCnt + "%" },
          attrs: { "data-cnt": qIndex },
        })
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "progress_islands" },
      _vm._l(_vm.islands, function (island, iIndex) {
        return _c("IslandPreview", {
          key: island.name + "_" + iIndex,
          staticClass: "progress-island",
          class: {
            passed: iIndex < _vm.softSkillIdx,
            current: iIndex == _vm.softSkillIdx,
            next: iIndex == _vm.softSkillIdx + 1,
          },
          style: {
            left:
              iIndex < _vm.currentSoftSkillIdx
                ? -6.3 - (_vm.getMaxIndex(_vm.softSkillIdx) - iIndex) * 3 + "%"
                : "auto",
            right:
              iIndex >= _vm.currentSoftSkillIdx
                ? -6.3 - (iIndex - _vm.currentSoftSkillIdx) * 3 + "%"
                : "auto",
            width: _vm.getIslandDimensions(iIndex),
            height: _vm.getIslandDimensions(iIndex),
            "z-index":
              iIndex < _vm.currentSoftSkillIdx ? 99 + iIndex : 99 - iIndex,
          },
          attrs: {
            id: "progress_island_" + iIndex,
            name: island.name,
            status: _vm.currentSoftSkillIdx > iIndex ? "completed" : "pending",
            title: island.name,
          },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }