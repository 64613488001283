<template>
  <div
    class="island-preview-box"
    :style="backgroundImage"
  />
</template>

<script>
export default {
  name: 'IslandPreview',
  props: {
    name: {
      type: String,
      required: true
    },
    status: {
      type: String,
      default: '',
      validator: val => {
        if (val === 'completed' || val === 'pending') {
          return true
        }
      }
    }
  },
  computed: {
    islandBackgroundImage() {
      if (this.status === 'completed') {
        return require(`@/assets/img/progress/islands/${this.name}.png`)
      }

      return require(`@/assets/img/progress/islands/${this.name}_closed.png`)
    },

    backgroundImage() {
      return `background-image: url('${this.islandBackgroundImage}');`
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~mixins';

  .island-preview-box {
    position: absolute;
    right: 11%;
    top: 41.2%;
    width: 10%;
    height: 10%;
    background-repeat: no-repeat;
    background-size: 100% auto;
    z-index: 10;
    @include transition(transform 0.3s linear);

    &.passed {
      left: 11%;
      right: auto;
    }

    &.current {
      left: 11%;
      right: auto;
      border: 1px solid #ffd536;
      border-radius: 50%;
    }

    &:hover {
      @include transform(rotate(20deg));
    }
  }

  @media only screen and (max-width: 1280px) {
    .island-preview-box {
      max-width: 64px;
      max-height: 64px;
    }
  }

  @include res('xs') {
    .island-preview-box {
      display: none;

      &.current {
        display: block;
        max-width: 40px;
        max-height: 40px;
        right: -10% !important;
        left: auto !important;
        top: -1% !important;
      }
    }
  }
</style>
