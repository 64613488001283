<template>
  <transition
    name="custom-fade"
    enter-active-class="animated fadeIn"
    leave-active-class="animated fadeOut"
    appear
  >
    <div
      v-show="showHelp"
      :id="type + '_help'"
      class="help_overlay"
    >
      <a
        class="img-btn info-button"
        @click="toggleHelpOverlay()"
      />
      <transition
        name="custom-slide"
        enter-active-class="animated slideInUp"
        leave-active-class="animated fadeOut"
        appear
      >
        <div
          v-show="showHelpPopup"
          class="help_txt"
          v-html="$t(type + '_help')"
        />
      </transition>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'HelpOverlay',
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showHelpPopup: false
    }
  },
  computed: {
    showHelp() {
      return this.$parent.$parent.showHelp
    }
  },
  methods: {
    toggleHelpOverlay() {
      this.showHelpPopup = !this.showHelpPopup
    }
  }
}
</script>
