var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bg_screen", attrs: { id: "end_screen" } }, [
    _c("div", { staticClass: "congratulations-box" }, [
      _c(
        "p",
        { style: _vm.styling },
        [
          _vm.isEarly
            ? [
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t("congratulations_early_1", { name: _vm.name })
                    ),
                  },
                }),
                !_vm.isPiraeus
                  ? _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("congratulations_early_2")),
                      },
                    })
                  : _vm._e(),
              ]
            : _vm.hasNoMoreAvailableThisMonth
            ? _c("span", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("congratulations_no_more_this_month")
                  ),
                },
              })
            : _c("span", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("congratulations", { name: _vm.name })
                  ),
                },
              }),
          _c("br"),
          !_vm.isPiraeus
            ? _c("a", { attrs: { href: _vm.dashboardGameScreen } }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("show_results")) + "\n      "
                ),
              ])
            : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }