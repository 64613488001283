var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bg_screen", attrs: { id: "end_screen" } }, [
    _c("div", { staticClass: "congratulations-box" }, [
      _c("p", { staticStyle: { "font-size": "0.8em" } }, [
        _c("span", {
          domProps: {
            innerHTML: _vm._s(
              _vm.$t("transition_" + _vm.step, { name: _vm.name })
            ),
          },
        }),
        _c("br"),
        _c("a", { attrs: { href: _vm.dashboardGameScreen } }, [
          _vm._v(_vm._s(_vm.$t("click_here"))),
        ]),
        _vm._v("\n      " + _vm._s(_vm.$t("if_no_redirect")) + "\n    "),
      ]),
    ]),
    _vm._v("\n  " + _vm._s(_vm.step) + "\n"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }