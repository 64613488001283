var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showButtons,
          expression: "showButtons",
        },
      ],
      staticClass: "narration_actions",
    },
    [
      _c("a", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.narrationPlaying,
            expression: "!narrationPlaying",
          },
        ],
        staticClass: "left replayNarration img-btn img-hover-btn",
        on: { click: _vm.replayNarration },
      }),
      _c("a", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.narrationPlaying,
            expression: "narrationPlaying",
          },
        ],
        staticClass: "right skipNarration img-btn img-hover-btn",
        on: { click: _vm.skipNarration },
      }),
      _c("a", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.narrationFinished,
            expression: "narrationFinished",
          },
        ],
        staticClass: "right continueButton img-btn img-hover-btn",
        on: { click: _vm.exitNarration },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }