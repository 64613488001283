var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: {
        name: "custom-fade",
        "enter-active-class": "animated fadeIn",
        "leave-active-class": "animated fadeOut",
        appear: "",
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.narrationPlayedOnce && !_vm.questionAnswered,
              expression: "narrationPlayedOnce && !questionAnswered",
            },
          ],
          staticClass: "answers_wrapper",
          attrs: { id: "answers_wrapper_" + _vm.question.id },
        },
        [
          _c(
            "div",
            { staticClass: "answer_draggables" },
            [
              _vm.showDragAnswerTip
                ? _c(
                    "transition",
                    {
                      attrs: {
                        name: "custom-fade",
                        "enter-active-class": "animated zoomIn",
                        "leave-active-class": "animated fadeOut",
                        appear: "",
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "drag_to_answer",
                        attrs: {
                          src: require("../assets/img/localization/" +
                            _vm.langKey +
                            "/drag-to-answer-white.png"),
                        },
                      }),
                    ]
                  )
                : _vm._e(),
              _c("div", { staticClass: "cell cell-6-col no-padding" }, [
                _c("img", {
                  attrs: {
                    id: "bestChoiceCoin",
                    src: require("@/assets/img/select-coin.png"),
                    alt: "",
                  },
                }),
                _c("h4", [_vm._v(_vm._s(_vm.$t("best_choice")))]),
              ]),
              _c("div", { staticClass: "cell cell-6-col no-padding" }, [
                _c("img", {
                  attrs: {
                    id: "worstChoiceCoin",
                    src: require("@/assets/img/reject-coin.png"),
                    alt: "",
                  },
                }),
                _c("h4", [_vm._v(_vm._s(_vm.$t("worst_choice")))]),
              ]),
              _c("HelpOverlay", { attrs: { type: "answer_draggables" } }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "answers", attrs: { id: "answers" } },
            [
              _vm._l(_vm.question.options, function (option) {
                return _c(
                  "div",
                  {
                    key: option.id,
                    class: [
                      "answer",
                      {
                        selected: option.id === _vm.bestOptionId,
                        rejected: option.id === _vm.worstOptionId,
                      },
                    ],
                    attrs: {
                      id: "answer_" + option.id,
                      "data-answerid": option.id,
                    },
                  },
                  [
                    _c("div", { staticClass: "answer_inner" }, [
                      _c("div", {
                        staticClass: "answer_txt",
                        domProps: { innerHTML: _vm._s(option.optionText) },
                      }),
                    ]),
                  ]
                )
              }),
              _c("HelpOverlay", { attrs: { type: "answers" } }),
            ],
            2
          ),
          _c("div", { staticClass: "answer_submit_wrapper" }, [
            _c("button", {
              ref: "submitAnswer",
              staticClass: "img-btn img-hover-btn",
              style: {
                opacity: _vm.bestOptionId > 0 && _vm.worstOptionId > 0 ? 1 : 0,
              },
              attrs: { id: "answer_submit", type: "button" },
              on: { click: _vm.submitAnswer },
            }),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }