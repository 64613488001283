<template>
  <div
    id="end_screen"
    class="bg_screen"
  >
    <div class="congratulations-box">
      <p style="font-size: 0.8em;">
        <span v-html="$t(`transition_${step}`, { name })" />
        <br>
        <a :href="dashboardGameScreen">{{ $t("click_here") }}</a>
        {{ $t("if_no_redirect") }}
      </p>
    </div>
    {{ step }}
  </div>
</template>

<script>
export default {
  name: 'TransitionScreen',
  computed: {
    dashboardGameScreen() {
      return process.env.VUE_APP_DASHBOARD_GAME_SCREEN
    },

    step() {
      if (this.$route.query && this.$route.query.step) {
        return this.$route.query.step // step-2 or step-3
      }

      return 'step-2' // fallback
    },

    name() {
      return this.$store.getters.name
    }
  },
  created() {
    this.$parent.$refs.header.allowMuteNarration = false
    this.$parent.$refs.header.allowFontEdit = false

    this.resetNarrationSound()

    setTimeout(() => {
      window.location = this.dashboardGameScreen
    }, 5 * 1000)
  }
}
</script>
