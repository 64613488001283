var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "question_main_wrapper",
      attrs: { id: "question_wrapper_" + _vm.question.id },
    },
    [
      _c(
        "div",
        { staticClass: "progress_bar_outer" },
        [_c("ProgressBar"), _c("HelpOverlay", { attrs: { type: "progress" } })],
        1
      ),
      _c(
        "div",
        { staticClass: "question_main" },
        [
          _c("div", { staticClass: "question-island" }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.$t("islands." + _vm.island.name.toLowerCase() + ".name")
                ) +
                "\n    "
            ),
          ]),
          _c(
            "transition",
            {
              attrs: {
                name: "custom-fade",
                "enter-active-class": "animated fadeIn",
                "leave-active-class": "animated fadeOut",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "question_text_container text_container",
                  attrs: { id: "question_text_container" },
                },
                [
                  _c("div", {
                    staticClass: "question_text",
                    attrs: { id: "question_text" },
                    domProps: { innerHTML: _vm._s(_vm.question.questionText) },
                  }),
                ]
              ),
            ]
          ),
          _c("NarrationButtons", { on: { continue: _vm.continueQuestion } }),
          _c("HelpOverlay", { attrs: { type: "question" } }),
        ],
        1
      ),
      _c(
        "transition",
        {
          attrs: {
            name: "custom-fade",
            "enter-active-class": "animated fadeInLeft",
          },
        },
        [
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.narrationPlayedOnce && !_vm.questionAnswered,
                expression: "narrationPlayedOnce && !questionAnswered",
              },
            ],
            attrs: { id: "narration_end_arrow" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }