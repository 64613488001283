<template>
  <div
    v-show="showButtons"
    class="narration_actions"
  >
    <a
      v-show="!narrationPlaying"
      class="left replayNarration img-btn img-hover-btn"
      @click="replayNarration"
    />

    <a
      v-show="narrationPlaying"
      class="right skipNarration img-btn img-hover-btn"
      @click="skipNarration"
    />

    <a
      v-show="narrationFinished"
      class="right continueButton img-btn img-hover-btn"
      @click="exitNarration"
    />
  </div>
</template>

<script>
export default {
  name: 'NarrationButtons',
  data() {
    return {
      showButtons: true
    }
  },
  computed: {
    narrationPlaying() {
      return this.$store.getters.narrationPlaying
    },
    narrationFinished() {
      return this.$store.getters.narrationFinished
    }
  },
  methods: {
    exitNarration() {
      if (window.completingGame) {
        this.showButtons = false
        return
      }

      this.$emit('continue')
    }
  }
}
</script>
