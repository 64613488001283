<template>
  <transition
    name="custom-fade"
    enter-active-class="animated fadeIn"
    leave-active-class="animated fadeOut"
    appear
  >
    <div
      v-show="narrationPlayedOnce && !questionAnswered"
      :id="'answers_wrapper_' + question.id"
      class="answers_wrapper"
    >
      <div class="answer_draggables">
        <transition
          v-if="showDragAnswerTip"
          name="custom-fade"
          enter-active-class="animated zoomIn"
          leave-active-class="animated fadeOut"
          appear
        >
          <img
            class="drag_to_answer"
            :src="require('../assets/img/localization/' + langKey + '/drag-to-answer-white.png')"
          >
        </transition>
        <div class="cell cell-6-col no-padding">
          <img
            id="bestChoiceCoin"
            src="@/assets/img/select-coin.png"
            alt
          >
          <h4>{{ $t("best_choice") }}</h4>
        </div>
        <div class="cell cell-6-col no-padding">
          <img
            id="worstChoiceCoin"
            src="@/assets/img/reject-coin.png"
            alt
          >
          <h4>{{ $t("worst_choice") }}</h4>
        </div>
        <HelpOverlay type="answer_draggables" />
      </div>
      <div
        id="answers"
        class="answers"
      >
        <div
          v-for="option in question.options"
          :id="'answer_' + option.id"
          :key="option.id"
          :class="['answer', {
            selected: option.id === bestOptionId,
            rejected: option.id === worstOptionId
          }]"
          :data-answerid="option.id"
        >
          <div class="answer_inner">
            <div
              class="answer_txt"
              v-html="option.optionText"
            />
          </div>
        </div>
        <HelpOverlay type="answers" />
      </div>
      <div class="answer_submit_wrapper">
        <button
          id="answer_submit"
          ref="submitAnswer"
          type="button"
          class="img-btn img-hover-btn"
          :style="{'opacity': ((bestOptionId > 0 && worstOptionId > 0) ? 1 : 0)}"
          @click="submitAnswer"
        />
      </div>
    </div>
  </transition>
</template>

<script>
import HelpOverlay from '@/components/HelpOverlay.vue'

export default {
  name: 'Answers',
  components: {
    HelpOverlay
  },
  data() {
    return {
      dragToAnswerTip: true
    }
  },
  computed: {
    langKey() {
      return this.$store.getters.langKey
    },
    question() {
      return this.$store.getters.question
    },
    questionIdx() {
      return this.$store.getters.questionIdx
    },
    bestOptionId() {
      return this.$store.getters.bestOptionId
    },
    worstOptionId() {
      return this.$store.getters.worstOptionId
    },
    narrationPlayedOnce() {
      return this.$store.getters.narrationPlayedOnce
    },
    questionAnswered() {
      return this.$store.getters.questionAnswered
    },
    questionSecondsTaken() {
      return this.$store.getters.questionSecondsTaken
    },
    optionSecondsTaken() {
      return this.$store.getters.optionSecondsTaken
    },

    showDragAnswerTip() {
      return this.softSkillIdx === 0 && this.questionIdx === 0 & this.narrationPlayedOnce && this.dragToAnswerTip
    }
  },
  mounted() {
    const container = jQuery('#answers_wrapper_' + this.question.id)
    const vm = this

    jQuery('#bestChoiceCoin,#worstChoiceCoin').draggable({
      revert: true,
      start: function(event, ui) {
        const draggable = jQuery(ui.draggable)
        if (draggable.is('.ui-draggable')) {
          if (draggable.is('#bestChoiceCoin')) {
            vm.$store.commit('setBestOptionId', 0)
          }
          else {
            vm.$store.commit('setWorstOptionId', 0)
          }
        }
      }
    })

    container.find('.answer').droppable({
      hoverClass: 'hovered',
      activate: function(event, ui) {
        vm.dragToAnswerTip = false
      },
      drop: function(event, ui) {
        const optionId = parseInt(jQuery(this).data('answerid'))
        const draggable = jQuery(ui.draggable)

        if (draggable.is('#bestChoiceCoin')) {
          vm.bestChoiceSound.play()
        }
        else {
          vm.worstChoiceSound.play()
        }
        if (draggable.is('#bestChoiceCoin')) {
          vm.$store.commit('setBestOptionId', optionId)
          if (optionId === vm.worstOptionId) {
            vm.$store.commit('setWorstOptionId', 0)
          }
        }
        else {
          vm.$store.commit('setWorstOptionId', optionId)
          if (optionId === vm.bestOptionId) {
            vm.$store.commit('setBestOptionId', 0)
          }
        }
      }
    })

    const answersTexts = container.find('.answer_txt')
    answersTexts.mCustomScrollbar({ theme: 'light-2' })
    this.addTooltips(answersTexts)

    container.find('.help_txt').mCustomScrollbar({ theme: 'minimal' })
  },

  methods: {
    submitAnswer() {
      this.continueSound.play()

      if (this.bestOptionId > 0 && this.worstOptionId > 0) {
        this.startBtnLoading(this.$refs.submitAnswer)

        this.$store.dispatch('setAnswer', {
          playerResultId: this.$store.getters.playerResultId,
          bestOptionSelectedId: this.bestOptionId,
          worstOptionSelectedId: this.worstOptionId,
          questionSecondsTaken: this.questionSecondsTaken,
          optionSecondsTaken: this.optionSecondsTaken
        })
          .then(this.proceedToQuestionOutcome)
      }
    },

    proceedToQuestionOutcome() {
      const options = TAFFY(this.question.options)
      const bestOption = options({ id: this.bestOptionId }).first()

      this.$store.commit('setQuestionAnswered', true)
      this.$store.commit('setNarrationPathAac', bestOption.outcomeTextSequence.narrationPathAac)
      this.$store.commit('setNarrationPathOgg', bestOption.outcomeTextSequence.narrationPathOgg)

      document.getElementById('question_text').innerHTML = bestOption.outcomeTextSequence.sequenceText

      this.startNarration(true)
      this.sendGameProgressToGA('question_answered', this.question.id, this.bestOptionId)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~mixins';

  .drag_to_answer {
    position: absolute;
    top: 60%;
    left: -42%;
    display: block;
    max-width: 40%;
    margin: 0;
    cursor: inherit;
    z-index: 99;

    @include res('xs') {
      display: none;
    }
  }
</style>
